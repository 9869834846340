/**
 * External Imports
 * */
import { connect, useSelector } from 'react-redux'
import { useMemo, useState, useEffect, useCallback } from 'react'
import { debounce } from 'lodash'
/**
 * Internal Imports
 * */
import LeftSidePanel from './leftSidePanel'
import Table from '../table/table'
import SearchBar from './searchbar'
import SubNavigation from '../headers/subNav/SubNav'
import { AcceptDeleteUser } from '../reusableComponents/deleteUserModal/DeleteUserModal'
import { GenericErrorSnackbar } from '../reusableComponents/errorPages/ErrorSnackbar'
import SlidingPane from '../reusableComponents/SlidingPane'

import { getUnitMetaData } from '../../redux/actions/units'
import { fetchRoomMetadata } from '../../redux/actions/rooms'
import { fetchSiteMetadata } from '../../redux/actions/sites'
import { getClinicalRoles } from '../../redux/actions/groupAndStaffSlotMetaData'
import {
  getAllUsers,
  managePageAuthenticateUser,
} from '../../redux/actions/user'
import {
  fetchAllLocations,
  fetchAdminLocationsByUnit,
} from '../../redux/actions/locations'

import siteDropdownSelector from '../../redux/selectors/siteDropdownSelector'

import { filterUsers } from '../../utils/filterUsers'
import { filterLocations } from '../../utils/filterLocations'
import { filterPatients } from '../../utils/filterPatients'
import {
  formatClinicalRoleDropdownData,
  formatDropdownData,
  formatSiteDropdownData,
} from '../../utils/formatDropdownData'
import {
  formatManageLocation,
  formatManagePatientsList,
} from '../../utils/formatManageLocation'

/**
 * Style Imports
 * */
import Flex from '../reusableComponents/styleLibrary'
import { DropShadowDiv } from './manage.styles'

const Manage = ({
  adminLocations,
  allBeds,
  allLocations,
  allUnits,
  authToken,
  bedLoading,
  clinicalRoles,
  coid,
  divisions,
  exportUsers,
  sites,
  siteMetaData,
  fetchingAllLocations,
  fetchingSiteMetaData,
  fetchingRoomMetadata,
  fetchingMetaData,
  fetchingUserExport,
  fetchingUsers,
  formattedSites,
  patientDivisions,
  roomMetadata,
  selPage,
  selRoute,
  selectedSiteId,
  selectedUnits,
  singleLocationToEdit,
  ui,
  unitMetaData,
  units,
  user,
  userSites,
  userSettings,
  dispatch,
  ...rest
}) => {
  const authorizedGlobalAdmin =
    user.user.authorizationRoles[0].displayName === 'Global Admin'
  const authorizedLocalAdmin =
    user.user.authorizationRoles[0].displayName === 'Local Admin'
  const path = window.location.pathname.replace('/manage/', '')
  const [searchText, setSearchText] = useState('')
  const [sanitizedSearchText, setSanitizedSearchText] = useState('')
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false)
  const [deleteUserData, setDeleteUserData] = useState({})
  const [sortDirection, setSortDirection] = useState(true)
  const [sortBy, setSortBy] = useState('lastName')
  const [selectedDivision, setSelectedDivision] = useState(
    path !== 'patients' ? divisions[0] : patientDivisions[0],
  )
  const [selectedPatientDivision, setSelectedPatientDivision] = useState(
    patientDivisions[0],
  )
  const [selectedSite, setSelectedSite] = useState(null)
  const [selectedPatientSite, setSelectedPatientSite] = useState(null)
  const [selectedClinicalRole, setSelectedClinicalRole] = useState(null)
  const [selectedUnit, setSelectedUnit] = useState(null)
  const [selectedPatientUnit, setSelectedPatientUnit] = useState(null)
  const [selectedRoom, setSelectedRoom] = useState(null)
  const [selectedBed, setSelectedBed] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [roomsList, setRoomsList] = useState([])
  const [unitsList, setUnitsList] = useState([])

  // Manage Sliding Pane: Slide In/Out
  const slidingPaneOpen = useSelector((state) => state.ui.slidingPane.open)
  const [slidingPaneState, setSlidingPaneState] = useState(slidingPaneOpen)
  const showPane = slidingPaneState

  useEffect(() => {
    if (slidingPaneOpen) {
      setSlidingPaneState(true)
    } else {
      const timer = setTimeout(() => {
        setSlidingPaneState(false)
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [slidingPaneOpen])

  const sitesForSelectedDivision = useMemo(() => {
    return sites?.sites?.filter(
      path === 'patients'
        ? (site) => site.grandparent.id === selectedPatientDivision?.value
        : (site) => site.grandparent.id === selectedDivision?.value,
    )
  }, [sites, path, selectedPatientDivision, selectedDivision])

  const [sitesList, setSitesList] = useState(
    formatManageLocation({
      locations:
        sitesForSelectedDivision.length > 0
          ? siteMetaData.filter((data) =>
              sitesForSelectedDivision.some(
                (site) => site.siteId === data.siteId,
              ),
            )
          : siteMetaData,
      locationType: 'Site',
    }),
  )
  const [bedsList, setBedsList] = useState([])

  const dataType = ui.managePageDataType //What data will be displayed to the user

  const formattedSitesForDropdown = formatSiteDropdownData(
    sitesForSelectedDivision,
  )
  const formattedUnitsForDropdown = formatDropdownData(allUnits)

  const formattedRoomsForDropdown = formatDropdownData(roomsList)

  const formattedBedsForDropdown = formatDropdownData(bedsList)

  const formattedClinicalRolesForDropdown =
    clinicalRoles && formatClinicalRoleDropdownData(clinicalRoles)

  const debounceSearchText = useCallback(
    debounce(() => {
      setSanitizedSearchText(searchText.trim())
    }, 250),
    [searchText],
  )

  useEffect(() => {
    debounceSearchText()
  }, [searchText])

  useEffect(() => {
    if (siteMetaData.length < 1 && !fetchingSiteMetaData) {
      const facilityMetaDataString = new Set(
        userSites.map((site) => site.facilityCoid),
      )
      dispatch(
        fetchSiteMetadata({
          authToken,
          facilityMetaDataString,
        }),
      )
    }
  }, [
    selectedUnits,
    siteMetaData,
    selectedDivision,
    fetchingSiteMetaData,
    dispatch,
    authToken,
  ])

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setUnitsList(
        formatManageLocation({
          locations: unitMetaData.reduce((acc, unit) => {
            const unitInSite = allUnits.find((u) => unit.id === u.id)
            return unitInSite
              ? [
                  ...acc,
                  {
                    ...unit,
                    siteId: unitInSite.siteId,
                  },
                ]
              : acc
          }, []),
          locationType: 'Unit',
        }),
      )
      selectedRoom
        ? setBedsList(
            formatManageLocation({
              locations: allBeds.filter(
                (bed) => bed.parent && bed.parent.id === selectedRoom?.value,
              ),
              locationType: 'Bed',
            }),
          )
        : setBedsList(
            formatManageLocation({
              locations: allBeds,
              locationType: 'Bed',
            }),
          )
      setRoomsList(
        formatManageLocation({
          locations: roomMetadata,
          locationType: 'Room',
          allBeds,
        }),
      )
    }
    return () => (isMounted = false)
  }, [
    allBeds,
    allLocations,
    allUnits,
    roomMetadata,
    selectedSite,
    selectedRoom,
    unitMetaData,
    units.units,
  ])

  useEffect(() => {
    const formattedSites = formatManageLocation({
      locations:
        sitesForSelectedDivision.length > 0
          ? siteMetaData.filter((data) =>
              sitesForSelectedDivision.some(
                (site) => site.siteId === data.siteId,
              ),
            )
          : siteMetaData,
      locationType: 'Site',
    })
    setSitesList(formattedSites)
  }, [sitesForSelectedDivision, siteMetaData, selectedDivision])

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      dispatch(
        getAllUsers({
          facilityId: selectedSite ? selectedSite.facilityId : null,
          authToken,
          searchText: ui.searchText,
          divisionId:
            selectedDivision && selectedDivision.value !== 'All'
              ? selectedDivision.value
              : null,
          clinicalRole: selectedClinicalRole
            ? selectedClinicalRole.systemName
            : null,
          skip: ui.skip,
          take: ui.take,
          sortBy,
          sortDirection: sortDirection ? 'asc' : 'desc',
        }),
      )
    }
    return () => (isMounted = false)
  }, [
    selectedDivision,
    selectedClinicalRole,
    ui.searchText,
    ui.skip,
    ui.take,
    sortBy,
    sortDirection,
  ])

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (path !== 'locations') {
        //if user navigates away from locations tab clear location data, dataType, and selections/lists
        dispatch({ type: 'locations/CLEAR_ALL_LOCATIONS' })
        dispatch({ type: 'manage/SET_DATA_TYPE', payload: 'Sites' })
        setSelectedDivision(divisions[0])
        setSelectedSite(null)
        setSelectedUnit(null)
        setSelectedRoom(null)
        setSelectedBed(null)
        setRoomsList([])
        setUnitsList([])
        setSitesList(
          formatManageLocation({
            locations:
              sitesForSelectedDivision.length > 0
                ? siteMetaData.filter((data) =>
                    sitesForSelectedDivision.some(
                      (site) => site.siteId === data.siteId,
                    ),
                  )
                : siteMetaData,
            locationType: 'Site',
          }),
        )
        setBedsList([])
      }
    }
    return () => (isMounted = false)
  }, [path])

  useEffect(() => {
    dispatch({
      type: 'assignments/FETCH_ADMIN_LOCATIONS_RECEIVED',
      payload: [],
    })
    setSelectedPatientUnit(null)
  }, [selectedDivision, selectedSite, dispatch])

  const showHideSlidingPaneClassName = ui.slidingPane.open
    ? 'modal display-block'
    : 'modal display-block'

  const filteredUsers = useMemo(
    () => filterUsers(sanitizedSearchText, user.allUsers),
    [sanitizedSearchText, user.allUsers],
  )

  const filteredPatients = useMemo(
    () =>
      filterPatients(
        sanitizedSearchText,
        formatManagePatientsList(adminLocations, path),
      ),
    [sanitizedSearchText, adminLocations, path],
  )

  //filter Locations lists
  const filteredSites = useMemo(
    () =>
      dataType === 'Sites'
        ? filterLocations(sanitizedSearchText, sitesList)
        : undefined,
    [dataType, sanitizedSearchText, sitesList],
  )
  const filteredUnits = useMemo(
    () =>
      dataType === 'Units'
        ? filterLocations(sanitizedSearchText, unitsList)
        : undefined,
    [dataType, sanitizedSearchText, unitsList],
  )
  const filteredRooms = useMemo(
    () =>
      dataType === 'Rooms'
        ? filterLocations(sanitizedSearchText, roomsList)
        : undefined,
    [dataType, sanitizedSearchText, roomsList],
  )
  const filteredBeds = useMemo(
    () =>
      dataType === 'Beds'
        ? filterLocations(sanitizedSearchText, bedsList)
        : undefined,
    [dataType, sanitizedSearchText, bedsList],
  )

  const handleSearch = (searchText) => setSearchText(searchText)

  const handleUserSearch = debounce((searchText) => {
    setSearchText(searchText)
    dispatch({
      type: 'user/SET_SEARCH',
      payload: {
        searching: true,
        searchText: searchText.trim(),
      },
    })
      dispatch(
        getAllUsers({
          facilityId: selectedSite ? selectedSite.facilityId : null,
          authToken,
          searchText: searchText.trim(),
          divisionId:
            selectedDivision?.value !== 'All' ? selectedDivision.value : null,
          clinicalRole: selectedClinicalRole
            ? selectedClinicalRole.systemName
            : null,
          skip: ui.skip,
          take: ui.take,
          sortBy,
          sortDirection: sortDirection ? 'asc' : 'desc',
        }),
      )
  }, 250)

  const handleLocationsTableSelection = (data, listType) => {
    dispatch({ type: 'manage/SET_DATA_TYPE', payload: listType })
    dispatch({
      type: 'user/SET_SEARCH',
      payload: {
        searching: false,
        searchText: '',
      },
    })
    setSearchText('')

    let selSite = formattedSites.filter((site) => site.id === data.id)[0]

    if (selSite) {
      const selDivisionId = sites?.sites?.filter(
        (site) => site.id === selSite.id,
      )[0]?.grandparent.id

      let selDivision = divisions.filter(
        (division) => division.value === selDivisionId,
      )[0]
      setSelectedDivision(selDivision)
      setSelectedSite(selSite)
      dispatch(fetchAllLocations(authToken, selSite.value, 'test'))
      dispatch(
        getUnitMetaData({
          authToken,
          siteId: selSite.value,
        }),
      )
    }
    const selUnit =
      !fetchingAllLocations &&
      formattedUnitsForDropdown.filter((unit) => {
        return data.id === unit.value
      })
    selUnit.length > 0 && setSelectedUnit(selUnit[0])

    const selBed =
      !fetchingAllLocations &&
      formattedBedsForDropdown.filter((bed) => {
        return data.id === bed.value
      })

    selBed.length > 0 && setSelectedBed(selBed[0])

    if (listType === 'Rooms') {
      // US 9153 - Calls fetchRoomMetadata when room table cell is selected.
      ui.managePageDataType === 'Units'
        ? dispatch(fetchRoomMetadata(authToken, data.siteId, data.id))
        : dispatch(fetchRoomMetadata(authToken, data.siteId))
      // selUnit.length > 0 ? setSelectedUnit(selUnit[0]) : setSelectedUnit(null)
    }

    if (listType === 'Beds') {
      const selRoom =
        !fetchingAllLocations &&
        formattedRoomsForDropdown.filter((room) => {
          return data.id === room.value
        })
      selRoom.length > 0 ? setSelectedRoom(selRoom[0]) : setSelectedRoom(null)
      if (selRoom.length < 1 && selUnit.length < 1) {
        setBedsList(allBeds)
      } else if (selUnit.length > 0) {
        const filteredBeds = allBeds.filter(
          (bed) => bed.parent && bed.unit.id === selUnit[0].value,
        )
        setBedsList(
          formatManageLocation({
            locations: filteredBeds,
            locationType: 'Bed',
          }),
        )
      } else if (selRoom.length > 0) {
        const filteredBeds = allBeds.filter(
          (bed) => bed.parent && bed.parent.id === selRoom[0].value,
        )
        setBedsList(
          formatManageLocation({
            locations: filteredBeds,
            locationType: 'Bed',
          }),
        )
      }
    }
  }

  const handleBackButton = (dataToShow) => {
    if (dataToShow === 'Sites') {
      setSelectedSite(null)
      if (!selectedDivision) {
        setSelectedDivision(divisions[0])
      }
    }
    if (dataToShow === 'Units') setSelectedUnit(null)
    if (dataToShow === 'Rooms') setSelectedRoom(null)
    if (dataToShow === 'Beds') setSelectedBed(null)
    dispatch({ type: 'manage/SET_DATA_TYPE', payload: dataToShow })
    dispatch({
      type: 'user/SET_SEARCH',
      payload: {
        searching: false,

        searchText: '',
      },
    })
    setSearchText('')
  }
  const handleSelectDivision = (e) => {
    let selDivision = divisions.filter(
      (division) =>
        division.text.toLowerCase() === e.target.innerText.toLowerCase(),
    )
    setSelectedSite(null)
    setSelectedUnit(null)
    setSelectedRoom(null)
    setSelectedBed(null)
    setSelectedClinicalRole(null)
    setSelectedDivision(selDivision[0])
    if (selRoute === 'patients') {
      setSelectedPatientDivision(
        e.target.innerText !== '' ? selDivision[0] : patientDivisions[0],
      )
    }
    setSelectedSite(null)
    setSitesList(
      formatManageLocation({
        locations: siteMetaData.filter((data) =>
          sitesForSelectedDivision.some((site) => site.siteId === data.siteId),
        ),
        locationType: 'Site',
      }),
    )
    dispatch({ type: 'manage/SET_DATA_TYPE', payload: 'Sites' })
  }

  const handleSelectSite = (e) => {
    dispatch({ type: 'user/CLEAR_USERS_FOR_ALL' })
    dispatch({ type: 'locations/CLEAR_ALL_LOCATIONS' })
    setSelectedUnit(null)
    setSelectedRoom(null)
    setSelectedBed(null)
    setSelectedClinicalRole(null)
    setSelectedStatus('All')
    let selSite = formattedSites.filter(
      (site) =>
        site.text.trim().toLowerCase() === e.target.innerText.toLowerCase(),
    )

    setSelectedSite(selSite[0])
    if (selRoute === 'patients') setSelectedPatientSite(selSite[0])

    if (!selSite.length && e.target.innerText !== 'All') return
    selSite = selSite[0]
    if (e.target.innerText === 'All') {
      dispatch({
        type: 'manage/SET_DATA_TYPE',
        payload: 'Sites',
      })
      setSelectedSite(null)
      setUnitsList([])
      dispatch(
        managePageAuthenticateUser({
          authToken,
          user34: user.user.hca34,
          siteId: selectedSiteId,
        }),
      )
      if (path === 'users') {
        dispatch({
          type: 'user/SET_PAGINATION',
          payload: { skip: 0, take: 50 },
        })
        dispatch(
          getAllUsers({
            facilityId: null,
            authToken,
            searchText: ui.searchText,
            divisionId: selectedDivision ? selectedDivision.value : null,
            clinicalRole: selectedClinicalRole
              ? selectedClinicalRole.systemName
              : null,
            skip: 0,
            take: 50,
            sortBy,
            sortDirection: sortDirection ? 'asc' : 'desc',
          }),
        )
      }
    } else {
      dispatch(
        managePageAuthenticateUser({
          authToken,
          user34: user.user.hca34,
          siteId: selSite.value,
        }),
      )
      dispatch(
        getUnitMetaData({
          authToken,
          siteId: selSite.value,
        }),
      )

      dispatch(
        getClinicalRoles({
          authorization: authToken,
          siteId: selSite.value,
        }),
      )
      //TODO fetch locations using /location endpoint and filter params
      //Only get new location data if the site was changed on locations page
      if (path === 'users') {
        dispatch({
          type: 'user/SET_PAGINATION',
          payload: { skip: 0, take: 50 },
        })
        dispatch(
          getAllUsers({
            facilityId: selSite ? selSite.facilityId : null,
            authToken,
            searchText: ui.searchText,
            divisionId:
              selectedDivision && selectedDivision.value !== 'All'
                ? selectedDivision.value
                : null,
            clinicalRole: null,
            skip: 0,
            take: 50,
            sortBy: null,
            sortDirection: sortDirection ? 'asc' : 'desc',
          }),
        )
      }
      if (path === 'locations' || path === 'patients') {
        dispatch(fetchAllLocations(authToken, selSite.value, 'test'))
        dispatch({
          type: 'manage/SET_DATA_TYPE',
          payload: 'Units',
        })
      }
    }
    setSelectedUnit(null)
    setSelectedRoom(null)
    setSelectedBed(null)
  }

  const handleSelectUnit = (e) => {
    setSelectedUnit(null)
    dispatch({ type: 'rooms/CLEAR_ROOM_METADATA' })
    setSelectedStatus('All')
    let selUnit
    if (selRoute === 'locations') {
      selUnit = formattedUnitsForDropdown.filter(
        (unit) =>
          unit.text.toLowerCase().trim() ===
          e.target.innerText.toLowerCase().trim(),
      )
      if (e.target.innerText.toLowerCase() !== 'all') {
        // US 9153 - On Room Page (after Unit is selected), call /api/Location/RoomMetadata/{unitId}
        dispatch({ type: 'manage/SET_DATA_TYPE', payload: 'Rooms' })
        dispatch(
          fetchRoomMetadata(
            authToken,
            selectedSite?.value.trim(),
            selUnit[0].value,
          ),
        )
      } else {
        dispatch({ type: 'manage/SET_DATA_TYPE', payload: 'Units' })
      }
    } else {
      selUnit =
        allUnits &&
        formattedUnitsForDropdown.filter(
          (unit) =>
            unit.text.toLowerCase() === e.target.innerText.toLowerCase(),
        )
      dispatch(
        fetchAdminLocationsByUnit(
          authToken,
          selectedSite?.value,
          selUnit[0]?.value,
        ),
      )
      if (selRoute === 'patients') setSelectedPatientUnit(selUnit[0])
    }
    setSelectedUnit(selUnit[0])
    setSelectedBed(null)
    setSelectedRoom(null)
  }

  const handleSelectRoom = (e) => {
    setSelectedStatus('All')
    if (e.target.innerText.toLowerCase() !== 'all') {
      dispatch({ type: 'manage/SET_DATA_TYPE', payload: 'Beds' })

      const room =
        formattedRoomsForDropdown &&
        formattedRoomsForDropdown.filter((room) => {
          return room.text.toLowerCase() === e.target.innerText.toLowerCase()
        })[0]
      setSelectedRoom(room)
      const filteredBeds =
        allBeds &&
        allBeds.filter((bed) => bed.parent && bed.parent.id === room.value)
      setBedsList(
        formatManageLocation({
          locations: filteredBeds,
          locationType: 'Bed',
        }),
      )
    } else {
      setSelectedRoom(null)
      dispatch({ type: 'manage/SET_DATA_TYPE', payload: 'Rooms' })
    }
    setSelectedBed(null)
  }

  const handleFilterByStatus = (e) => {
    const selectedStatus = e.target.innerText
    setSelectedStatus(selectedStatus)
    switch (ui.managePageDataType) {
      case 'Units':
        const filteredUnits = unitMetaData.reduce((acc, unit) => {
          const unitInSite = allUnits.find((u) => unit.id === u.id)
          return unitInSite
            ? [
                ...acc,
                {
                  ...unit,
                  siteId: unitInSite.siteId,
                },
              ]
            : acc
        }, [])
        if (selectedStatus !== 'All') {
          const filteredByStatusUnitList = filteredUnits.filter(
            (unit) => unit.status === selectedStatus,
          )
          setUnitsList(
            formatManageLocation({
              locations: filteredByStatusUnitList,
              locationType: 'Unit',
            }),
          )
        } else {
          setUnitsList(
            formatManageLocation({
              locations: filteredUnits,
              locationType: 'Unit',
            }),
          )
        }
        break
      case 'Rooms':
        if (selectedStatus !== 'All') {
          const filteredByStatusRoomList = roomMetadata.filter(
            (room) => room.status === selectedStatus,
          )
          setRoomsList(
            formatManageLocation({
              locations: filteredByStatusRoomList,
              locationType: 'Room',
              allBeds,
            }),
          )
        } else {
          setRoomsList(
            formatManageLocation({
              locations: roomMetadata,
              locationType: 'Room',
              allBeds,
            }),
          )
        }
        break
      case 'Beds':
        const filteredBeds =
          allBeds &&
          allBeds.filter(
            (bed) => bed.parent && bed.parent.id === selectedRoom.value,
          )
        if (e.target.innerText !== 'All') {
          const filteredByStatusBedList = filteredBeds.filter(
            (bed) => bed.status === selectedStatus,
          )
          setBedsList(
            formatManageLocation({
              locations: filteredByStatusBedList,
              locationType: 'Bed',
            }),
          )
        } else {
          setBedsList(
            formatManageLocation({
              locations: filteredBeds,
              locationType: 'Bed',
            }),
          )
        }
        break
      default:
        return
    }
  }

  const handleSelectClinicalRole = (e) => {
    const selClinicalRole = formattedClinicalRolesForDropdown.filter(
      (role) => role.text.toLowerCase() === e.target.innerText.toLowerCase(),
    )[0]
    setSelectedClinicalRole(selClinicalRole)
    
    dispatch(
      getAllUsers({
        facilityId: selectedSite ? selectedSite.facilityId : null,
        authToken,
        searchText: sanitizedSearchText,
        divisionId: selectedDivision ? selectedDivision.value : null,
        clinicalRole: selClinicalRole ? selClinicalRole.systemName : null,
        skip: ui.skip,
        take: ui.take,
        sortBy,
        sortDirection: sortDirection ? 'asc' : 'desc',
      }),
    )
  }
  window.addEventListener('click', (e) => {
    if (
      ui.flashMessage.messages.length > 0 &&
      e.target.className !== 'snackbar'
    ) {
      dispatch({
        type: 'ui/FLASH_MESSAGE',
        payload: {
          messages: [],
          type: '',
          uuid: '',
        },
      })
    }
  })

  return (
    <Flex direction={'column'}>
      <AcceptDeleteUser
        showDeleteUserModal={showDeleteUserModal}
        setShowDeleteUserModal={setShowDeleteUserModal}
        hideSlidingPane={() =>
          dispatch({
            type: 'ui/MANAGE_SLIDING_PANE',
            payload: {
              open: false,
              source: ui.slidingPane.source,
              data: {},
              userExists: false,
            },
          })
        }
        deleteUserData={deleteUserData}
        dispatch={dispatch}
      />
      <SubNavigation
        page={selPage}
        handleSlidingPane={showHideSlidingPaneClassName}
      />
      {ui.flashMessage.messages.length > 0 ? (
        <GenericErrorSnackbar ui={ui} />
      ) : (
        <></>
      )}
      <Flex style={{ width: '100%' }}>
        <LeftSidePanel
          userPermissions={user.userPermissions}
          site={selectedSiteId}
          allUsers={filteredUsers}
          sites={formattedSitesForDropdown}
          clinicalRoles={formattedClinicalRolesForDropdown}
          selectedClinicalRole={selectedClinicalRole}
          handleSelectClinicalRole={(e) => handleSelectClinicalRole(e)}
          handleSelectSite={handleSelectSite}
          handleSelectDivision={(e) => handleSelectDivision(e)}
          handleSelectUnit={(e) => handleSelectUnit(e)}
          handleSelectRoom={(e) => handleSelectRoom(e)}
          handleFilterByStatus={(e) => handleFilterByStatus(e)}
          selectedDivision={selectedDivision}
          selectedPatientDivision={selectedPatientDivision}
          selectedPatientSite={selectedPatientSite}
          selectedPatientUnit={selectedPatientUnit}
          selectedUnit={selectedUnit}
          selectedRoom={selectedRoom}
          selectedBed={selectedBed}
          selectedStatus={selectedStatus}
          path={path}
          divisions={divisions}
          formattedBeds={formattedBedsForDropdown}
          formattedRooms={formattedRoomsForDropdown}
          formattedUnits={formattedUnitsForDropdown}
          formattedUnitsForPatientDropdown={formattedUnitsForDropdown}
          selectedAdminSite={selectedSite}
          selectedAdminUnit={units.selectedAdminUnit}
          defaultSite={selectedSite}
          authorizedLocalAdmin={authorizedLocalAdmin}
          authorizedGlobalAdmin={authorizedGlobalAdmin}
          authToken={authToken}
          ui={ui}
          exportUsers={exportUsers}
          dispatch={dispatch}
          fetchingUserExport={fetchingUserExport}
        />
        <DropShadowDiv style={{ maxWidth: 'calc(100vw - 327px)' }}>
          <div
            style={{
              paddingTop: '25px',
              flex: 1,
            }}
          >
            <SearchBar
              authToken={authToken}
              clinicalRoles={clinicalRoles}
              userPermissions={user.userPermissions}
              dataType={dataType}
              handleBackButton={handleBackButton}
              searchText={searchText}
              selectedAdminSite={selectedSite}
              selectedUnit={selectedUnit}
              selectedRoom={selectedRoom}
              selectedBed={selectedBed}
              selectedSite={selectedSite}
              handleSearch={path === 'users' ? handleUserSearch : handleSearch}
              path={path}
              authorizedGlobalAdmin={authorizedGlobalAdmin}
              authorizedLocalAdmin={authorizedLocalAdmin}
              dispatch={dispatch}
              {...rest}
            />

            {showPane && (
              <SlidingPane
                loggedInUser={userSettings.hca34}
                userPermissions={user.userPermissions}
                hideSlidingPane={() =>
                  dispatch({
                    type: 'ui/MANAGE_SLIDING_PANE',
                    payload: {
                      open: false,
                      source: ui.slidingPane.source,
                      data: ui.slidingPane.data,
                      userExists: false,
                    },
                  })
                }
                setDeleteUserData={setDeleteUserData}
                setShowDeleteUserModal={setShowDeleteUserModal}
                slidingPane={ui.slidingPane}
                dispatch={dispatch}
                showHideClassName={showHideSlidingPaneClassName}
                ui={ui}
                allLocations={allLocations}
                authToken={authToken}
                coid={coid}
                path={path}
                bedLoading={bedLoading}
                sites={formatSiteDropdownData(sites.sites)}
                clinicalRoles={clinicalRoles}
                authorizedGlobalAdmin={authorizedGlobalAdmin}
                authorizedLocalAdmin={authorizedLocalAdmin}
                selectedSite={ui.slidingPane.data.siteId}
                selectedAdminSite={selectedSite}
                selectedDivision={selectedDivision}
                selectedUnit={selectedUnit}
                selectedRoom={selectedRoom}
                selectedClinicalRole={selectedClinicalRole}
                units={allUnits}
                beds={allBeds}
                singleLocationToEdit={singleLocationToEdit}
                sortDirection={sortDirection}
                sortBy={sortBy}
              />
            )}
            <div />
            <Table
              style={{ marginBottom: 50 }}
              setSortDirection={setSortDirection}
              setSortBy={setSortBy}
              sortDirection={sortDirection}
              sortBy={sortBy}
              allUsers={filteredUsers}
              path={path}
              ui={ui}
              fetchingAllLocations={fetchingAllLocations}
              selectedAdminSite={selectedSite}
              selectedSiteId={selectedSite}
              selectedClinicalRole={selectedClinicalRole}
              selectedDivision={selectedDivision}
              authToken={authToken}
              facilityCoid={coid}
              handleLocationsTableSelection={handleLocationsTableSelection}
              patientList={filteredPatients}
              rooms={filteredRooms}
              roomMetadata={roomMetadata}
              fetchingRoomMetadata={fetchingRoomMetadata}
              sitesList={filteredSites}
              units={filteredUnits}
              beds={filteredBeds}
              dataType={dataType}
              searchText={searchText}
              fetchingMetaData={fetchingMetaData}
              fetchingUsers={fetchingUsers}
              dispatch={dispatch}
              sites={formattedSites}
              authorizedGlobalAdmin={authorizedGlobalAdmin}
              authorizedLocalAdmin={authorizedLocalAdmin}
              fetchingSiteMetaData={fetchingSiteMetaData}
            />
          </div>
        </DropShadowDiv>
      </Flex>
    </Flex>
  )
}

const mapReduxStateToProps = function (state, props) {
  //State from Store
  return {
    authToken: state.user.token,
    fetchingMetaData: state.units.fetchingMetaData,
    selectedSiteId: state.sites.selectedSiteId,
    user: state.user,
    clinicalRolesForAddUser: state.userGroups.clinicalRolesForAddUser,
    clinicalRoles: state.userGroups.clinicalRoles,
    ui: state.ui,
    units: state.units,
    patientLoading: state.locations.fetchingAdminLocations,
    adminLocations: state.locations.adminLocations,
    fetchingAllLocations: state.locations.fetchingAllLocations,
    unitMetaData: state.units.unitMetaData,
    allLocations: state.locations.allLocations,
    userSites: state.sites.sites,
    allUnits: state.locations.allUnits,
    allRooms: state.locations.allRooms,
    allBeds: state.locations.allBeds,
    patients: state.patients.patients,
    userSlidingPaneSites: state.sites.userSlidingPaneSites,
    beds: state.addLocations.beds,
    rooms: state.addLocations.rooms,
    divisions: state.divisions.divisions,
    patientDivisions: state.divisions.patientDivisions,
    exportUsers: state.user.exportUsers,
    fetchingUserExport: state.user.fetchingUserExport,
    fetchingUsers: state.user.fetchingUsers,
    fetchingSiteMetaData: state.sites.fetchingSiteMetaData,
    siteMetaData: state.sites.siteMetaData,
    siteMetaDataError: state.sites.siteMetaDataError,
    roomMetadata: state.rooms.roomMetadata,
    fetchingRoomMetadata: state.rooms.fetching,
    selectedUnitIds: state.units.selectedUnitIds,
    singleLocationToEdit: state.locations.singleLocationToEdit,
    ...siteDropdownSelector(state, props),
  }
}

export default connect(mapReduxStateToProps)(Manage)
