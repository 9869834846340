// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import environment from '../environment'

const firebaseApiKey = environment._firebaseApiKey
const firebaseAuthDomain = environment._firebaseAuthDomain
const firebaseDatabaseURL = environment._firebaseDatabaseURL
const firebaseProjectId = environment._firebaseProjectId
const firebaseStorageBucket = environment._firebaseStorageBucket
const firebaseMessagingId = environment._firebaseMessagingId
const firebaseAppId = environment._firebaseAppId
const firebaseMeasurementId = environment._firebaseMeasurementId

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: firebaseApiKey,
  authDomain: firebaseAuthDomain,
  databaseURL: firebaseDatabaseURL,
  projectId: firebaseProjectId,
  storageBucket: firebaseStorageBucket,
  messagingSenderId: firebaseMessagingId,
  appId: firebaseAppId,
  measurementId: firebaseMeasurementId,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

logEvent(analytics, 'notification_received')

export default analytics
