/**
 * External Imports
 * */
import 'react-app-polyfill/ie9'
import 'babel-polyfill'
import 'core-js'
import { createRoot } from 'react-dom/client'
/**
 * Internal Imports
 * */
import App from './App'
import * as serviceWorker from './serviceWorker'
/**
 * Styling Imports
 * */
import './analytics/firebase'
import './index.css'
import { StrictMode } from 'react'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <StrictMode>
    <App className="fch100" style={{ height: '100% !important' }} />
  </StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
