import { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import './App.scss'
import './index.css'
import jwt_decode from 'jwt-decode'
import $ from 'jquery'
import './customStyles.scss'

import store from './redux/store'
import { saveUserToken, saveUser34 } from './redux/actions/user'
import { getUserSites } from './redux/actions/sites'
import Unauthorized from './components/reusableComponents/errorPages/401'
import GateKeeper from './components/auth/GateKeeper'

import {
  AuthorizationServiceConfiguration,
  RedirectRequestHandler,
  BaseTokenRequestHandler,
  AuthorizationNotifier,
  TokenRequest,
  GRANT_TYPE_AUTHORIZATION_CODE,
} from '@openid/appauth'

import configInfo from './pingConfig.json'

import { NeuApp } from '@neutron/react'

const App = () => {
  const [responseReturned, setResponseReturned] = useState(true)

  window.$ = $

  const developmentEnvironment = process.env.REACT_APP_ENV
  const devENV = developmentEnvironment.toLowerCase()

  const envRedirect = window.location.origin + '/redirect'
  let client_id
  let openIdConnectUrl
  if (devENV.includes('local')) {
    openIdConnectUrl = configInfo.devqaOpenIdConnectUrl
    client_id = configInfo.devqaClient_id
  } else if (devENV.includes('dev')) {
    openIdConnectUrl = configInfo.devqaOpenIdConnectUrl
    client_id = configInfo.devqaClient_id
  } else if (devENV.includes('qa')) {
    openIdConnectUrl = configInfo.devqaOpenIdConnectUrl
    client_id = configInfo.devqaClient_id
  } else if (devENV.includes('prod')) {
    openIdConnectUrl = configInfo.prodOpenIdConnectUrl
    client_id = configInfo.prodClient_id
  } else {
    console.warn('No environment set')
  }
  const redirect_uri = envRedirect
  const [mounted, setMounted] = useState(true)

  useEffect(() => {
    if (mounted) {
      fetchConfig().then((res) => {
        const notifier = new AuthorizationNotifier()
        // Set a listener to listen for authorization responses
        const authorizationHandler = new RedirectRequestHandler()

        // Set notifier to deliver responses
        authorizationHandler.setAuthorizationNotifier(notifier)

        notifier.setAuthorizationListener((request, response) => {
          console.log('Authorization request complete ')
          if (response) {
            response.request = request
            response.response = response
            const code = response.code

            tokenRequest(request, res, code)
          }
        })

        // This sets up the listener
        authorizationHandler.completeAuthorizationRequestIfPossible()
      })
    }
    setMounted(false)
  }, [mounted])

  const tokenRequest = (req, configuration, code) => {
    if (code) {
      let extras = {}
      if (req && req.internal) {
        extras['code_verifier'] = req.internal['code_verifier']
      }
      req = new TokenRequest({
        client_id,
        redirect_uri,
        grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
        code,
        refresh_token: undefined,
        extras,
      })
    }
    if (req) {
      const tokenHandler = new BaseTokenRequestHandler()
      const tokenResponse = {}
      tokenHandler

        .performTokenRequest(configuration, req)
        .then((response) => {
          if (response) {
            // copy over new fields
            tokenResponse.accessToken = response.accessToken
            tokenResponse.issuedAt = response.issuedAt
            tokenResponse.expiresIn = response.expiresIn
            tokenResponse.tokenType = response.tokenType
            tokenResponse.scope = response.scope
          } else {
            tokenResponse.res = response
            setResponseReturned(false)
          }

          // unset code, so we can do refresh token exchanges subsequently
          code = undefined

          // THIS IS FOR SENDING ACCESS TOKEN TO BACKEND
          const decodedToken = jwt_decode(response.accessToken)

          const user34 = decodedToken.subject

          const accessToken = 'bearer ' + response.accessToken
          const authToken = accessToken
          localStorage.setItem('auth token', authToken)
          store.dispatch(saveUserToken(authToken))
          store.dispatch(saveUser34(user34))
          store.dispatch(getUserSites({ user34, authToken }))
        })
        .catch(function (error) {
          console.log('Something bad happened', error)
        })
    }
  }

  const fetchConfig = () => {
    return AuthorizationServiceConfiguration.fetchFromIssuer(openIdConnectUrl)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log('Something bad happened', error)
      })
  }

  return responseReturned ? (
    <Provider
      className="fch100"
      style={{ height: '100% !important' }}
      store={store}
    >
      <NeuApp>
        <GateKeeper style={{ height: '100%' }} />
      </NeuApp>
    </Provider>
  ) : (
    <Unauthorized />
  )
}

// export default withAITracking(reactPlugin, App)
export default App
