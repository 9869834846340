/**
 * External Imports
 * */
import { useState, useEffect } from 'react'
import _ from 'lodash'
import { NeuIcon } from '@neutron/react'
/**
 * Internal Imports
 * */
import {
  postUser,
  updateUser,
  updateUserRestricted,
} from '../../../redux/actions/user'
/**
 * Styling Imports
 * */
import Flex from '../styleLibrary'
import { FlatTabButton } from '../styleLibrary'
import Input from '../neutronComponents/input/input'
import { Dropdown } from 'semantic-ui-react'
import { LabelDisplay, Button, SlidingPaneDiv } from './userSlidingPane.styles'
import { useSelector } from 'react-redux'

const UserSlidingPane = (props) => {
  const { showHideClassName, hideSlidingPane, loggedInUser, data, ui } = props

  const slidingPaneOpen = useSelector((state) => state.ui.slidingPane.open)
  const modalSlide = slidingPaneOpen ? 'slideLeft' : 'slideRight'

  const formatOptions = (roles) =>
    roles.map((role, index) => {
      return {
        value: role.systemName,
        text: role.displayName,
        key: role.id,
      }
    })
  let facilitiesForDropdown =
    data && data.assignedLocations && data.assignedLocations.length > 0
      ? props.facilities.filter(
          (facility) =>
            !data.assignedLocations.some(
              (location) => location.id === facility.id,
            ),
        )
      : props.facilities

  const dropdownSites = facilitiesForDropdown.map((site) => ({
    displayname: site.displayName,
    id: site.id,
    key: site.id,
    text: site.text,
    value: site.value,
  }))
  const clinicalRoles = formatOptions(props.clinicalRoles)

  const sortedRoles =
    clinicalRoles &&
    clinicalRoles.sort(function (a, b) {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    })

  const [firstName, setFirstName] = useState(
    props.source === 'users' ? data.firstName : '',
  )
  const [lastName, setLastName] = useState(
    props.source === 'users' ? data.lastName : '',
  )
  const [hca34, setHca34] = useState(props.source === 'users' ? data.hca34 : '')

  const [clinicalRole, setClinicalRole] = useState(
    data.clinicalRole
      ? sortedRoles.filter((r) => r.value === data.clinicalRole.systemName)[0]
      : sortedRoles[0],
  )

  const [showAddFacilityMessage, setShowAddFacilityMessage] = useState(false)
  const [height, setHeight] = useState(window.innerHeight - 120)

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      const tableHeight = (e) => {
        setHeight(e - 120)
      }
      window.addEventListener(
        'resize',
        _.debounce((e) => {
          const target = e.currentTarget
            ? e.currentTarget.innerHeight
            : e.srcElement.innerHeight
          return tableHeight(target)
        }, 750),
      )
    }
    return () => (isMounted = false)
  })

  const [facilitiesToAdd, setUserFacilitiesToAdd] = useState([])
  const [facilities, setUserFacilities] = useState(data.assignedLocations || [])
  const [invalidState, setInvalidState] = useState({
    firstName: false,
    lastName: false,
    hca34: false,
    clinicalRole: false,
    facilities: false,
  })
  const addNewUser = props.source === 'add-user-button'
  const [requiredFields, setRequiredFields] = useState([
    { ugly: 'firstName', pretty: 'First Name' },
    { ugly: 'lastName', pretty: 'Last Name' },
    { ugly: 'hca34', pretty: '3-4 ID' },
    { ugly: 'clinicalRole', pretty: 'Clinical Role' },
    { ugly: 'facilities', pretty: 'Facilities' },
  ])

  const validateForm = (form) => {
    const capturedInvalidState = Object.assign({}, invalidState)

    requiredFields.forEach((field) => {
      if (
        (props.source !== 'users' && !form[field.ugly]) ||
        (props.source !== 'users' && form[field.ugly].length < 1)
      ) {
        capturedInvalidState[field.ugly] = true
      } else {
        capturedInvalidState[field.ugly] = false
      }
    })
    const assignedFacilities = facilities.map((fac) => {
      return { id: fac.id, displayName: fac.text || fac.displayName }
    })
    const addedFacilities = facilitiesToAdd.map((fac) => {
      return { id: fac.id, displayName: fac.text || fac.displayName }
    })
    const assignedLocations = [...assignedFacilities, ...addedFacilities]
    if (assignedLocations.length < 1) setShowAddFacilityMessage(true)

    setInvalidState(capturedInvalidState)
    if (
      !capturedInvalidState.firstName &&
      !capturedInvalidState.lastName &&
      !capturedInvalidState.hca34 &&
      !capturedInvalidState.clinicalRole &&
      assignedLocations.length > 0
    ) {
      if (!addNewUser) {
        const user = {
          id: data.id,
          clinicalRole: props.clinicalRoles.filter(
            (role) => role.systemName === clinicalRole.value,
          )[0],
          assignedLocations,
          authorizationRoles: data.authorizationRoles,
          integrationProperties: data.integrationProperties,
          firstName,
          lastName,
          hca34,
        }
        if (props.userPermissions.includes('DetailedEditUser')) {
          props.dispatch(
            updateUser({
              user,
              siteId: props.selectedSite,
              authToken: props.authToken,
              loggedInUser,
              searchText: props.ui.searchText,
              divisionId: props.selectedDivision.value,
              skip: props.ui.skip,
              take: props.ui.take,
              sortBy: props.sortBy,
              sortDirection: props.sortDirection,
              selectedAdminSite: props.selectedAdminSite,
              selectedClinicalRole:
                (props.selectedClinicalRole &&
                  props.selectedClinicalRole.systemName) ||
                null,
            }),
          )
        } else if (
          !props.userPermissions.includes('DetailedEditUser') &&
          props.userPermissions.includes('SimpleEditUser')
        ) {
          props.dispatch(
            updateUserRestricted({
              user,
              siteId: props.selectedSite,
              authorization: props.authToken,
              loggedInUser,
              searchText: props.ui.searchText,
              divisionId: props.selectedDivision.value,
              skip: props.ui.skip,
              take: props.ui.take,
              sortBy: props.sortBy,
              sortDirection: props.sortDirection,
              selectedAdminSite: props.selectedAdminSite,
              selectedClinicalRole:
                (props.selectedClinicalRole &&
                  props.selectedClinicalRole.systemName) ||
                null,
            }),
          )
        }
      } else {
        const filteredClinicalRole = props.clinicalRoles.filter(
          (role) => role.systemName === form.clinicalRole.value,
        )[0]
        const user = {
          firstName: form.firstName,
          lastName: form.lastName,
          hca34: form.hca34,
          clinicalRole: filteredClinicalRole,
          assignedLocations,
        }

        props.dispatch(
          postUser({
            user,
            authorization: props.authToken,
            siteId: props.selectedSite,
            searchText: props.ui.searchText,
            divisionId: props.selectedDivision.value,
            skip: props.ui.skip,
            take: props.ui.take,
            sortBy: props.sortBy,
            sortDirection: props.sortDirection ? 'asc' : 'desc',
            selectedAdminSite: props.selectedAdminSite,
            selectedClinicalRole:
              (props.selectedClinicalRole &&
                props.selectedClinicalRole.systemName) ||
              null,
          }),
        )
      }
      if (props.ui.slidingPane.userExists !== true) {
        props.hideSlidingPane()
      }
    }
  }

  const handleChange = (e, { value }) => {
    const updatedSelectedFacilities = props.facilities.filter((facility) =>
      value.includes(facility.value),
    )

    setUserFacilitiesToAdd(updatedSelectedFacilities)
  }

  return (
    <div
      className={showHideClassName}
      style={{ overflowY: 'scroll', display: 'inline-block' }}
    >
      <div
        onClick={hideSlidingPane}
        style={{ height: '100%', width: '100%', display: 'inline-block' }}
      />
      <SlidingPaneDiv className={modalSlide}>
        <div
          style={{
            marginBottom: '142px',
            overflow: 'hidden',
            height: height + 'px',
          }}
        >
          <div style={{ padding: '15px 30px', height: '350px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Flex direction="row">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <h6
                    style={{ fontWeight: 'bold', fontFamily: 'HCA-Mark-Bold' }}
                  >
                    User Details
                  </h6>
                </div>
              </Flex>

              <FlatTabButton onClick={hideSlidingPane}>
                <i
                  className="neu-avatar__icon material-icons"
                  style={{
                    color: 'grey',
                    cursor: 'pointer',
                  }}
                >
                  close
                </i>
              </FlatTabButton>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Flex
                direction="column"
                style={{ flex: '1', padding: '5px', minHeight: '80px' }}
              >
                <Flex>
                  <div style={{ marginRight: '5px', marginTop: '5px' }}>
                    <LabelDisplay>First Name</LabelDisplay>

                    <Input
                      type="text"
                      handleInput={(e) => setFirstName(e)}
                      value={firstName}
                      style={{
                        width: '100%',
                        height: '40px',
                        lineHeight: '40px',
                        border: '1px solid #d9d9d6',
                        borderRadius: '5px',
                        marginBottom: '10px',
                        fontSize: '16px',
                        padding: '0 15px',
                        color: '#1f2532',
                      }}
                    />
                  </div>
                  <div style={{ marginRight: '5px', marginTop: '5px' }}>
                    <LabelDisplay>Last Name</LabelDisplay>

                    <Input
                      type="text"
                      handleInput={(e) => setLastName(e)}
                      value={lastName}
                      style={{
                        width: '100%',
                        height: '40px',
                        lineHeight: '40px',
                        border: '1px solid #d9d9d6',
                        borderRadius: '5px',
                        marginBottom: '10px',
                        fontSize: '16px',
                        padding: '0 15px',
                        color: '#1f2532',
                      }}
                    />
                  </div>
                </Flex>
              </Flex>
              <Flex
                direction="column"
                style={{ flex: '1', padding: '5px', minHeight: '80px' }}
              >
                <LabelDisplay>3-4 ID</LabelDisplay>
                {props.userPermissions.includes('DetailedEditUser') ? (
                  <Input
                    type="text"
                    handleInput={(e) => setHca34(e)}
                    value={hca34.toUpperCase()}
                    style={{
                      width: '100%',
                      height: '40px',
                      lineHeight: '40px',
                      border: '1px solid #d9d9d6',
                      borderRadius: '5px',
                      marginBottom: '10px',
                      padding: '0 15px',
                      fontSize: '18px',
                      color: '#1f2532',
                    }}
                  />
                ) : (
                  <div
                    style={{
                      fontSize: '18px',
                      color: '#1F2433',
                      fontFamily: 'HCA-Mark-Bold',
                      padding: '10px 0',
                    }}
                  >
                    {hca34.toUpperCase()}
                  </div>
                )}
                <LabelDisplay>Role</LabelDisplay>
                {props.userPermissions.includes('DetailedEditUser') ? (
                  <Dropdown
                    style={{
                      border: 'solid lightgrey 1px',
                      borderRadius: '5px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      marginBottom: '10px',
                      width: '100%',
                    }}
                    ui="true"
                    value={clinicalRole.value}
                    floating
                    search
                    selection
                    options={sortedRoles}
                    onChange={(e, d) => {
                      e.persist()
                      const role = sortedRoles.filter(
                        (r) =>
                          r.text.toLowerCase() ===
                          e.target.innerText.toLowerCase(),
                      )[0]
                      setClinicalRole(role)
                    }}
                  />
                ) : (
                  <div
                    style={{
                      fontSize: '18px',
                      color: '#1F2433',
                      fontFamily: 'HCA-Mark-Bold',
                      padding: '10px 0',
                    }}
                  >
                    {clinicalRole.text}
                  </div>
                )}
                {props.userPermissions.includes('RemoveUser') &&
                  !addNewUser && (
                    <Flex style={{ justifyContent: 'center' }}>
                      <div
                        style={{
                          fontSize: '18px',
                          fontFamily: 'HCA-Mark-Bold',
                          padding: '20px 0',
                          backgroundColor: 'white',
                          cursor: 'pointer',
                          color: '#ce2130',
                        }}
                        onClick={() => {
                          props.setDeleteUserData({
                            id: data.id,
                            authToken: props.authToken,
                            siteId: props.selectedSite,
                            ui,
                            loggedInUser,
                            searchText: props.ui.searchText,
                            divisionId: props.selectedDivision.value,
                            clinicalRole: props.selectedClinicalRole
                              ? props.selectedClinicalRole.systemName
                              : null,
                            skip: props.ui.skip,
                            take: props.ui.take,
                            sortBy: props.sortBy,
                            sortDirection: props.sortDirection,
                            selectedAdminSite: props.selectedAdminSite,
                          })
                          props.setShowDeleteUserModal(true)
                        }}
                      >
                        Delete User
                      </div>
                    </Flex>
                  )}
              </Flex>
            </div>
          </div>
          <div>
            <Flex
              direction="column"
              style={{
                minHeight: '420px',
                overflowY: 'scroll',
                width: '420px',
                paddingBottom: '92px',
              }}
            >
              {props.userPermissions.includes('DetailedEditUser') && (
                <>
                  <div style={{ borderTop: '1px solid #ddd' }} />
                  <Flex style={{ padding: '5px 30px' }} direction="column">
                    <p
                      style={{
                        fontWeight: 'bold',
                        fontFamily: 'HCA-Mark-Bold',
                        marginBottom: '10px',
                      }}
                    >
                      Facilities
                    </p>
                    {showAddFacilityMessage && (
                      <p style={{ fontSize: '10px', color: 'red' }}>
                        Please select a facility.
                      </p>
                    )}

                    <Dropdown
                      style={{
                        border: 'solid lightgrey 1px',
                        borderRadius: '5px',
                        marginBottom: '10px',
                        width: '100%',
                      }}
                      ui="true"
                      placeholder={'Select Facility'}
                      floating
                      search
                      multiple
                      selection
                      options={dropdownSites || []}
                      value={facilitiesToAdd.map((fac) => fac.value)}
                      onChange={handleChange}
                    />
                  </Flex>
                </>
              )}
              {data.assignedLocations && (
                <>
                  <div style={{ borderTop: '1px solid #ddd' }} />
                  <div style={{ padding: '5px 30px' }}>
                    <p
                      style={{
                        fontWeight: 'bold',
                        fontFamily: 'HCA-Mark-Bold',
                        marginTop: '5px',
                        marginBottom: '15px',
                      }}
                    >
                      Facilities ({data.assignedLocations.length})
                    </p>
                  </div>
                  <div style={{ borderTop: '1px solid #ddd' }} />
                </>
              )}
              <Flex
                direction="column"
                style={{
                  maxHeight: '300px',

                  overflowY: 'scroll',
                  width: '420px',
                  marginBottom: '10px',
                }}
              >
                {data.assignedLocations &&
                  _.sortBy(facilities, 'displayName').map(
                    ({ displayName, id }) => {
                      return (
                        <div key={id}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: '5px 30px',
                              marginBottom: '10px',
                            }}
                          >
                            <p>{displayName}</p>
                            {props.userPermissions.includes(
                              'DetailedEditUser',
                            ) && (
                              <FlatTabButton
                                onClick={() => {
                                  const newListOfFacilities = facilities.filter(
                                    (facility) => facility.id !== id,
                                  )
                                  setUserFacilities(newListOfFacilities)
                                }}
                              >
                                <NeuIcon
                                  large
                                  style={{ color: '#ce2130' }}
                                  className="neu-avatar__icon material-icons neu-button--danger"
                                >
                                  delete
                                </NeuIcon>
                              </FlatTabButton>
                            )}
                          </div>
                          <div style={{ borderTop: '1px solid #ddd' }} />
                        </div>
                      )
                    },
                  )}
              </Flex>
            </Flex>
          </div>
        </div>

        <div
          style={{
            position: 'fixed',
            bottom: 0,
            width: '408px',
            borderTop: '2px solid #bbb',
            backgroundColor: 'white',
          }}
        >
          <Flex style={{ margin: '20px 30px' }}>
            <Button
              save={false}
              style={{ cursor: 'pointer', color: '#E05929' }}
              onClick={hideSlidingPane}
            >
              Cancel
            </Button>
            <Button
              save={true}
              style={{
                cursor: 'pointer',
                backgroundColor: '#00558C',
                color: 'white',
              }}
              onClick={(e) =>
                validateForm({
                  firstName,
                  lastName,
                  hca34,
                  clinicalRole,
                  facilities,
                })
              }
            >
              Save
            </Button>
          </Flex>
        </div>
      </SlidingPaneDiv>
    </div>
  )
}
export default UserSlidingPane
