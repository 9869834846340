/**
 * External Imports
 * */
import { useState, useEffect } from 'react'
import _ from 'lodash'
/**
 * Internal Imports
 * */
import PrintCSV from '../reusableComponents/print/PrintCSV'
/**
 * Style Imports
 * */
import Flex from '../reusableComponents/styleLibrary'
import { Dropdown } from 'semantic-ui-react'
import { Container } from './manage.styles'

const LeftSidePanel = ({
  path,
  divisions,
  sites,
  formattedUnits,
  formattedRooms,
  clinicalRoles,
  selectedPatientDivision,
  selectedDivision,
  selectedPatientSite,
  selectedAdminSite,
  handleSelectSite,
  handleSelectUnit,
  selectedPatientUnit,
  selectedUnit,
  formattedUnitsForPatientDropdown,
  selectedRoom,
  handleSelectRoom,
  handleSelectDivision,
  selectedStatus,
  handleFilterByStatus,
  selectedClinicalRole,
  handleSelectClinicalRole,
  userPermissions,
  exportUsers,
  fetchingUserExport,
}) => {
  const divisionOptions =
    path === 'patients'
      ? divisions.filter((division) => division.value !== 'All')
      : divisions
  const allOption = [{ key: 'All', value: 'All', text: 'All' }]
  const siteOptions = path === 'patients' ? sites : allOption.concat(sites)
  const dropdownSites = siteOptions.map((site) => ({
    facilitycoid: site.facilityCoid,
    id: site.id,
    key: site.key,
    parent: site.parent,
    text: site.text,
    value: site.value,
  }))

  const unitOptions = allOption.concat(formattedUnits)
  const roomOptions = allOption.concat(formattedRooms)
  const statusOptions = [
    { key: 'All', value: 'All', text: 'All' },
    { key: 'Active', value: 'Active', text: 'Active' },
    { key: 'Hidden', value: 'Hidden', text: 'Hidden' },
  ]

  const clinicalOptions = allOption.concat(clinicalRoles)

  const [height, setHeight] = useState(window.innerHeight - 120)

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      const tableHeight = (e) => {
        setHeight(e - 120)
      }
      window.addEventListener(
        'resize',
        _.debounce((e) => {
          const target = e.currentTarget
            ? e.currentTarget.innerHeight
            : e.srcElement.innerHeight
          return tableHeight(target)
        }, 750),
      )
    }
    return () => (isMounted = false)
  })

  return (
    <Container style={{ height: height + 'px' }}>
      <Flex
        direction={'column'}
        style={{ padding: '10px 15px', marginLeft: '15px' }}
      >
        <label className="genericLabel">Division</label>
        <Dropdown
          className="genericDropdown"
          ui="true"
          value={
            path === 'patients'
              ? selectedPatientDivision && selectedPatientDivision.value
              : selectedDivision.value
          }
          placeholder={path === 'patients' ? 'Please select a division' : ''}
          floating
          search
          selection
          options={divisionOptions}
          onChange={(e) => {
            e.persist()
            handleSelectDivision(e)
          }}
        />
        <label className="genericLabel">Facility</label>
        <Dropdown
          className="genericDropdown"
          ui="true"
          value={
            path === 'patients'
              ? selectedPatientSite && selectedPatientSite.value
              : selectedAdminSite
              ? selectedAdminSite.value
              : siteOptions[0] && siteOptions[0].value
          }
          placeholder={path === 'patients' ? 'Please select a site' : ''}
          disabled={path !== 'patients' && selectedDivision.value === 'All'}
          floating
          search
          selection
          options={dropdownSites || []}
          onChange={(e) => {
            e.persist()
            handleSelectSite(e)
          }}
        />

        {path !== 'users' && (
          <>
            <label className="genericLabel">Units</label>
            <Dropdown
              className="genericDropdown"
              ui="true"
              value={
                path === 'patients'
                  ? selectedPatientUnit && selectedPatientUnit.value
                  : selectedUnit
                  ? selectedUnit.value
                  : unitOptions[0].value
              }
              floating
              search
              selection
              placeholder={path === 'patients' ? 'Please select a unit' : ''}
              options={
                path === 'patients'
                  ? formattedUnitsForPatientDropdown
                  : unitOptions
              }
              disabled={selectedAdminSite ? false : true}
              onChange={(e) => {
                e.persist()
                handleSelectUnit(e)
              }}
            />
          </>
        )}
        {path === 'locations' && (
          <>
            <label className="genericLabel">Rooms</label>
            <Dropdown
              className="genericDropdown"
              ui="true"
              disabled={selectedUnit ? false : true}
              value={
                selectedRoom
                  ? selectedRoom.value.trim()
                  : roomOptions[0].value.trim()
              }
              placeholder="All"
              floating
              search
              selection
              options={roomOptions}
              onChange={(e) => {
                e.persist()
                handleSelectRoom(e)
              }}
            />
            <label className="genericLabel">Status</label>
            <Dropdown
              className="genericDropdown"
              ui="true"
              disabled={selectedAdminSite ? false : true}
              value={selectedStatus ? selectedStatus : statusOptions[0].value}
              floating
              search
              selection
              options={statusOptions}
              onChange={(e) => {
                e.persist()
                handleFilterByStatus(e)
              }}
            />
          </>
        )}
        <div />
        {path === 'users' && (
          <>
            <label
              style={{
                paddingTop: '20px',
                fontSize: '14px',
                color: '#1F2532',
                fontFamily: 'HCA-Mark, Arial, sans-serif',
              }}
            >
              Role
            </label>
            <Dropdown
              className="genericDropdown"
              ui="true"
              value={
                selectedClinicalRole
                  ? selectedClinicalRole.value
                  : clinicalOptions[0].value
              }
              floating
              search
              selection
              options={clinicalOptions}
              onChange={(e) => {
                e.persist()
                handleSelectClinicalRole(e)
              }}
            />
          </>
        )}
      </Flex>
      {userPermissions.includes('ExportUsers') &&
        selectedAdminSite !== null &&
        path === 'users' && (
          <Flex
            style={{
              width: '100%',
              height: '100px',
              background: '#FFF',
              boxShadow: 'inset -10px 0px 5px -8px rgba(0, 0, 0, 0.2)',
              borderTop: '1px solid #DDD',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {exportUsers.length > 50 && (
              <PrintCSV
                list={exportUsers}
                fetchingUserExport={fetchingUserExport}
                page={'users'}
              />
            )}
          </Flex>
        )}
    </Container>
  )
}

export default LeftSidePanel
