/**
 * External Imports
 * */
import { useCallback, useEffect, useRef, useState } from 'react'
/**
 * Internal Imports
 * */
import Flex from '../reusableComponents/styleLibrary'
import _ from 'lodash'

export const MultiSelectDropdown = ({
  selections,
  options,
  updateDropdownList,
  id,
}) => {
  const [showHide, setShowHide] = useState(false)
  const [optionsListTop, setOptionsListTop] = useState(50)
  const mainRef = useRef()

  const handleClick = useCallback((e) => {
    if (
      e.target.id === id + ':multiSelectCaret' ||
      e.target.id === id + ':multiselectChild'
    ) {
      setShowHide((prevShowHide) => !prevShowHide);
    } else {
      setShowHide(false);
    }
  }, [id])

  useEffect(() => {
    const { height } = mainRef.current.getBoundingClientRect() || 50;
    setOptionsListTop(height + 10);

    const debouncedHandleClick = _.debounce(handleClick, 300);
    window.addEventListener('click', debouncedHandleClick);

    return () => {
      window.removeEventListener('click', debouncedHandleClick);
    };
  }, [handleClick]);

  return (
    <div>
      <Flex
        id="multiselectParent"
        direction="row"
        style={{
          width: '390px',
          position: 'relative',
          border: '1px solid #ddd',
          marginBottom: 10,
          marginTop: '10px',
          borderRadius: 5,
          padding: '5px 15px',
          minHeight: '42px',
        }}
        ref={mainRef}
      >
        <Flex
          style={{
            flex: '1 1 150px',
            justifyContent: 'flex-start',
            alignItems: 'center',
            backgroundColor: '#FFF',
            width: '170px',
            flexWrap: 'wrap',
          }}
          id={id + ':multiselectChild'}
        >
          {selections.map((p, index) => (
            <Flex
              key={p.text + index}
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                background: '#D9D9D6',
                color: '#1F2532',
                margin: '2.5px 5px',
                padding: '0 15px',
                maxWidth: '100%',
                textAlign: 'center',
                borderRadius: '25px',
                border: `3px solid #D9D9D6`,
              }}
            >
              {p.text}
              <i
                className="material-icons"
                style={{
                  marginLeft: 5,
                  color: '#898B8E',
                  cursor: 'pointer',
                  fontSize: '13px',
                  fontWeight: 'bold',
                  // padding: '0px 15px',
                }}
                onClick={() => {
                  updateDropdownList([
                    ...selections.filter(
                      (someOption) => p.text !== someOption.text,
                    ),
                  ])
                }}
              >
                remove_circle_outline
              </i>
            </Flex>
          ))}
        </Flex>
        {showHide && options.length > 0 && (
          <div
            style={{
              top: optionsListTop,
              left: 0,
              width: '100%',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '5px',
              height: 'max-content',
              maxHeight: 200,
              overflowY: 'scroll',
              zIndex: 99,
              boxShadow: '1px 2px 5px 1px rgba(0, 0, 0, 0.21)',
            }}
          >
            {options &&
              options.map((option, index) => {
                return (
                  <div
                    key={option.text + index}
                    style={{
                      padding: '10px',
                      cursor: 'pointer',
                      fontFamily: 'sans-serif',
                      boxSizing: 'border-box',
                      textAlign: 'left',
                      borderBottom: '0.5px solid rgba(0, 0, 0, 0.3)',
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      if (
                        selections.some(
                          (selection) => selection.text === 'All',
                        ) &&
                        option.text !== 'All'
                      ) {
                        updateDropdownList([option])
                      } else if (
                        selections.some(
                          (selection) => selection.text !== 'All',
                        ) &&
                        option.text === 'All'
                      ) {
                        updateDropdownList([option])
                      } else if (
                        selections.some(
                          (selection) => selection.text === option.text,
                        )
                      ) {
                        return
                      } else {
                        updateDropdownList([...selections, option])
                        return
                      }
                    }}
                  >
                    {option.text}
                  </div>
                )
              })}
          </div>
        )}
        <div
          style={{
            position: 'absolute',
            right: 0,
            marginTop: '2px',
            cursor: 'pointer',
          }}
        >
          <i className="material-icons" id={id + ':multiSelectCaret'}>
            {!showHide || options.length === 0
              ? 'arrow_drop_down'
              : 'arrow_drop_up'}
          </i>
        </div>
      </Flex>
    </div>
  )
}
