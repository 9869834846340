const environment = process.env.REACT_APP_ENV

let _serverUrl = null
let _type = null
let _azureRoleName = null
let _firebaseApiKey = null
let _firebaseAuthDomain = null
let _firebaseDatabaseURL = null
let _firebaseProjectId = null
let _firebaseStorageBucket = null
let _firebaseMessagingId = null
let _firebaseAppId = null
let _firebaseMeasurementId = null

const assignEnvs = () => {
  _azureRoleName = process.env.REACT_APP_AZURE_ROLE_NAME

  //firebase env keys
  _firebaseApiKey = process.env.REACT_APP_FIREBASE_KEY
  _firebaseAuthDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
  _firebaseDatabaseURL = process.env.REACT_APP_FIREBASE_DATABASE_URL
  _firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID
  _firebaseStorageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
  _firebaseMessagingId = process.env.REACT_APP_FIREBASE_MESSAGING_ID
  _firebaseAppId = process.env.REACT_APP_FIREBASE_APP_ID
  _firebaseMeasurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

if (environment) {
  _type = environment.toLowerCase()
  switch (_type) {
    case 'local':
      _serverUrl = 'https://careteam-qa.hcahealthcare.cloud/api/'
      assignEnvs()
      break
    case 'devcloud':
      _serverUrl = 'https://careteam-dev.hcahealthcare.cloud/api/'
      assignEnvs()
      break
    case 'qacloud':
      _serverUrl = 'https://careteam-qa.hcahealthcare.cloud/api/'
      assignEnvs()
      break
    case 'prodcloud':
      _serverUrl = 'https://careteam.hcahealthcare.cloud/api/'
      assignEnvs()
      break
    default:
      _serverUrl = 'devurl.com'
  }
} else {
  throw Error('App environment variable is not set')
}

export default {
  _serverUrl,
  _type,
  _azureRoleName,
  _firebaseApiKey,
  _firebaseAuthDomain,
  _firebaseDatabaseURL,
  _firebaseProjectId,
  _firebaseStorageBucket,
  _firebaseMessagingId,
  _firebaseAppId,
  _firebaseMeasurementId,
}
