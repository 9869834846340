const initialState = {
  user: null,
  token: null,
  user34: null,
  userPermissions: [],
  fetching: false,
  error: null,
  fetchingUserSettings: false,
  userSettings: {},
  userSettingError: null,
  fetchingGlobalUserSettings: false,
  globalUserSettings: {},
  globalUserSettingError: null,
  allUnitsSelected: false,
  allUsers: [],
  allUsersError: null,
  fetchingManagePageUserError: false,
  managePageUser: [],
  managePageUserError: null,
  fetchingUsers: false,
  exportUsers: [],
  fetchingUserExport: false,
  frontendLock: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_SHOW_TUTORIAL':
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          showTutorial: action.payload,
        },
      }
    case 'user/FETCH_AUTHENTICATE_USER':
      return { ...state, fetching: true }
    case 'user/FETCHING_ALL_USERS':
      return { ...state, fetchingUsers: true }
    case 'user/FETCH_AUTHENTICATE_USER_ERROR':
      return { ...state, error: action.payload, fetching: false }
    case 'user/FETCH_AUTHENTICATE_USER_RECEIVED':
      return { ...state, user: action.payload, fetching: false }
    case 'user/FETCH_USER_PERMISSIONS_RECEIVED':
      const userAuthRole = state.user.authorizationRoles[0].systemName
      const filteredPermissions = action.payload[userAuthRole]
      return { ...state, userPermissions: filteredPermissions }
    case 'user/FETCH_MANAGE_PAGE_AUTHENTICATE_USER':
      return { ...state, fetchingManagePageUser: true }
    case 'user/EXPORTING_USERS':
      return { ...state, fetchingUserExport: true }
    case 'user/EXPORT_USERS_RECEIVED':
      return {
        ...state,
        exportUsers: action.payload,
        fetchingUserExport: false,
      }
    case 'user/FETCH_MANAGE_PAGE_AUTHENTICATE_USER_RECEIVED':
      return {
        ...state,
        managePageUser: action.payload,
        fetchingManagePageUser: false,
      }
    case 'user/FETCH_MANAGE_PAGE_AUTHENTICATE_USER_ERROR':
      return {
        ...state,
        managePageUserError: action.payload,
        fetchingManagePageUser: false,
      }
    case 'user/SAVE_USER_TOKEN':
      return { ...state, token: action.payload }
    case 'user/SAVE_USER_34':
      return { ...state, user34: action.payload }
    case 'user/FETCHING_USER_SETTINGS':
      return { ...state, fetchingUserSettings: action.payload }
    case 'user/FETCH_USER_SETTINGS':
      return { ...state, userSettings: action.payload }
    case 'user/FETCHING_GLOBAL_USER_SETTINGS':
      return { ...state, fetchingGlobalUserSettings: action.payload }
    case 'user/FETCH_GLOBAL_USER_SETTINGS_SUCCESS':
      return {
        ...state,
        globalUserSettings: action.payload,
        fetchingGlobalUserSettings: false,
      }
    case 'user/UPDATE_GLOBAL_USER_SETTINGS_ERROR':
      return {
        ...state,
        globalUserSettingError: action.payload,
        fetchingGlobalUserSettings: false,
      }
    case 'user/FETCH_USER_SETTINGS_ERROR':
      return { ...state, userSettingError: action.payload }
    case 'user/UPDATE_USER_SETTINGS':
      return { ...state, userSettings: action.payload }
    case 'user/UPDATE_USER_SETTINGS_ERROR':
      return { ...state, userSettingError: action.payload }
    case 'user/ALL_UNITS_SELECTED':
      return { ...state, allUnitsSelected: action.payload }
    case 'user/FETCH_USERS_RECEIVED':
      return { ...state, allUsers: action.payload, fetchingUsers: false }
    case 'user/CLEAR_USERS':
      return { ...state, allUsers: [], fetchingUsers: false }
    case 'user/FETCH_USERS_ERROR':
      return { ...state, allUsersError: action.payload, fetchingUsers: false }
    case 'metadata/FETCH_APPLICATION_STATUS_RECEIVED':
      return { ...state, frontendLock: action.payload.frontendLock }
    default:
      return state
  }
}
