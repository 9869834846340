import axios from '../../utils/axiosWrapper'
import environment from '../../environment'
import { filterLocations } from '../../utils/filterLocations'
import { getUnitMetaData } from './units'
import { fetchSiteMetadata } from './sites'
import ErrorCheck from '../../utils/errorCheck'
import { getUserSites } from './sites'
import { fetchRoomMetadata } from './rooms'
import { fetchDraftAssignments } from './draftAssignments'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

const serverUrl = environment._serverUrl

export const fetchAllAssignments = (authToken, siteId, ui) => (dispatch) => {
  let urlForAuthUser = serverUrl + `Assignment/GetBySite/${siteId}`

  dispatch({ type: 'assignments/FETCH_LOCATIONS' })
  axios({
    method: 'get',
    url: urlForAuthUser,
    headers: {
      authorization: authToken,
      siteId,
      Pragma: 'no-cache',
    },
  })
    .then(function (response) {
      let activeLocations = []
      const assignments = response.data
      const idsOfStaffAssignmentsOnLocations = []
      const idsOfStaffAssignmentsOnPatients = []
      assignments.forEach((loc) => {
        //get location based assignments and grab staff ids for staffpool filtering
        loc.location.assignments &&
          loc.location.assignments.forEach((assignment) =>
            idsOfStaffAssignmentsOnLocations.push(assignment.user.id),
          )
        //get patient based assignments and grab staff ids for staffpool filtering
        loc.patient &&
          loc.patient.assignments &&
          loc.patient.assignments.forEach((assignment) =>
            idsOfStaffAssignmentsOnPatients.push(assignment.user.id),
          )
      })
      const idsOfStaffWithAssignments = idsOfStaffAssignmentsOnLocations.concat(
        idsOfStaffAssignmentsOnPatients,
      )

      const uniqIdsOfStaffWithAssignments =
        idsOfStaffWithAssignments.length > 0
          ? _.uniq(idsOfStaffWithAssignments)
          : [] //remove duplicates from list
      assignments.forEach((location) => {
        if (location.location.status === 'Active') {
          location.location.uuid = uuid()
          activeLocations.push(location)
        }
      })

      dispatch({
        type: 'assignments/FETCH_LOCATIONS_RECEIVED',
        payload: {
          activeLocations: activeLocations,
          uniqIdsOfStaffWithAssignments: uniqIdsOfStaffWithAssignments,
        },
      })

      const bedsSearchResults = filterLocations(ui.searchText, activeLocations)
      if (ui.searching) {
        dispatch({
          type: 'locations/SET_SEARCH',
          payload: {
            searching: true,
            bedsSearchResults:
              bedsSearchResults.length > 0 ? bedsSearchResults : [],
            searchText: ui.searchText,
          },
        })
      }
    })
    .catch(function (error) {
      ErrorCheck(error)

      if (error.response.status === 404) {
        dispatch({
          type: 'assignments/FETCH_LOCATIONS_RECEIVED',
          payload: [],
        })
      } else {
        dispatch({
          type: 'assignments/FETCH_LOCATIONS_ERROR',
          payload: error.response,
        })
      }
    })
}
export const fetchLocationsWithMultipleUnits =
  (authToken, siteId, unitIds, ui, draft) => (dispatch) => {
    let urlForAuthUser
    // if (unitId === 'all' || unitIds.includes('00000000-0000-0000-0000-000000000000')) {
    //   urlForAuthUser = serverUrl + `Assignment/`
    // } else {
    urlForAuthUser = serverUrl + `Assignment/GetAssignmentsForMultipleUnits`
    // }

    if (unitIds) {
      const data = unitIds.filter((id) => id != null)
      dispatch({ type: 'assignments/FETCH_LOCATIONS' })
      axios({
        method: 'post',
        url: urlForAuthUser,
        data: data,
        headers: {
          authorization: authToken,
          siteId,
          Pragma: 'no-cache',
        },
      })
        .then(function (response) {
          let activeLocations = []
          const assignments = response.data
          const idsOfStaffAssignmentsOnLocations = []
          const idsOfStaffAssignmentsOnPatients = []
          assignments.forEach((loc) => {
            //get location based assignments and grab staff ids for staffpool filtering
            loc.location.assignments &&
              loc.location.assignments.forEach((assignment) =>
                idsOfStaffAssignmentsOnLocations.push(assignment.user.id),
              )
            //get patient based assignments and grab staff ids for staffpool filtering
            loc.patient &&
              loc.patient.assignments &&
              loc.patient.assignments.forEach((assignment) =>
                idsOfStaffAssignmentsOnPatients.push(assignment.user.id),
              )
          })
          const idsOfStaffWithAssignments =
            idsOfStaffAssignmentsOnLocations.concat(
              idsOfStaffAssignmentsOnPatients,
            )

          const uniqIdsOfStaffWithAssignments =
            idsOfStaffWithAssignments.length > 0
              ? _.uniq(idsOfStaffWithAssignments)
              : [] //remove duplicates from list
          assignments.forEach((location) => {
            if (location.location.status === 'Active') {
              location.location.uuid = uuid()
              activeLocations.push(location)
            }
          })

          dispatch({
            type: 'assignments/FETCH_LOCATIONS_RECEIVED',
            payload: {
              activeLocations: activeLocations,
              uniqIdsOfStaffWithAssignments: uniqIdsOfStaffWithAssignments,
            },
          })

          if (draft === 'draft') {
            dispatch({ type: 'ui/IS_DRAFT_ASSIGNMENT', payload: true })
          }

          if (ui.isDraftAssignment || draft === 'draft') {
            dispatch(
              fetchDraftAssignments(
                authToken,
                siteId,
                unitIds,
                ui,
                activeLocations,
              ),
            )
          }
          const bedsSearchResults = filterLocations(
            ui.searchText,
            activeLocations,
          )

          if (ui.searching) {
            dispatch({
              type: 'locations/SET_SEARCH',
              payload: {
                searching: true,
                bedsSearchResults:
                  bedsSearchResults.length > 0 ? bedsSearchResults : [],
                searchText: ui.searchText,
              },
            })
          }
        })
        .catch(function (error) {
          ErrorCheck(error)

          if (error.response.status === 404) {
            dispatch({
              type: 'assignments/FETCH_LOCATIONS_RECEIVED',
              payload: [],
            })
          } else {
            dispatch({
              type: 'assignments/FETCH_LOCATIONS_ERROR',
              payload: error.response,
            })
          }
        })
    } else
      dispatch({
        type: 'assignments/FETCH_LOCATIONS_RECEIVED',
        payload: [],
      })
  }

export const fetchAllLocations = (authToken, siteId) => (dispatch) => {
  const urlForAuthUser = serverUrl + `Location`
  dispatch({ type: 'locations/FETCH_ALL_LOCATIONS' })
  axios({
    method: 'get',
    url: urlForAuthUser,
    headers: {
      authorization: authToken,
      siteId,
      Pragma: 'no-cache',
    },
  })
    .catch(function (error) {
      ErrorCheck(error)
      console.log(error.response)
    })
    .then(function (response) {
      dispatch({
        type: 'locations/FETCH_ALL_LOCATIONS_RECEIVED',
        payload: { data: response.data, siteId },
      })
    })
}

export const fetchAdminLocationsByUnit =
  (authToken, siteId, unitId) => (dispatch) => {
    let urlForAuthUser
    urlForAuthUser = serverUrl + `Assignment/${unitId}`

    dispatch({ type: 'assignments/FETCH_ADMIN_LOCATIONS' })
    axios({
      method: 'get',
      url: urlForAuthUser,
      headers: {
        authorization: authToken,
        siteId,
        Pragma: 'no-cache',
      },
    })
      .catch(function (error) {
        console.log(error?.response)
      })
      .then(function (response) {
        if (!unitId) {
          dispatch({
            type: 'assignments/FETCH_ADMIN_LOCATIONS_RECEIVED',
            payload: [],
          })
        } else {
          dispatch({
            type: 'assignments/FETCH_ADMIN_LOCATIONS_RECEIVED',
            payload: response.data,
          })
        }
      })
  }

export const getSingleLocation =
  ({ authToken, siteId, locationId }) =>
  (dispatch) => {
    const endpointForAuthUser = serverUrl + `Location/${locationId}`
    axios({
      method: 'get',
      url: endpointForAuthUser,
      headers: {
        Authorization: authToken,
        siteId,
        Pragma: 'no-cache',
      },
    })
      .then(function (response) {
        dispatch({
          type: 'locations/GET_SINGLE_LOCATION',
          payload: response.data,
        })
      })
      .catch(function (error) {
        ErrorCheck(error)
        dispatch({
          type: 'locations/GET_SINGLE_LOCATION_ERROR',
          payload: error.response,
        })
      })
  }

export const updateLocationStatus =
  (
    authToken,
    siteId,
    cascade,
    locationId,
    data,
    facilityCoids,
    user34,
    unitId,
  ) =>
  (dispatch) => {
    const urlForAuthUser =
      serverUrl + `Location/LocationStatus/${locationId}?cascade=${cascade}`
    dispatch({ type: 'location/CLEAR_ALL_META_DATA' })
    axios({
      method: 'post',
      url: urlForAuthUser,
      data,
      headers: {
        authorization: authToken,
        siteId,
        Pragma: 'no-cache',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .catch(function (error) {
        ErrorCheck(error)
        console.log(error.response)
      })
      .then(function (response) {
        if (unitId) {
          dispatch(fetchRoomMetadata(authToken, siteId, unitId))
        } else {
          dispatch(fetchRoomMetadata(authToken, siteId))
        }
        dispatch(
          fetchSiteMetadata({
            facilityMetaDataString: new Set(facilityCoids),
            authToken,
          }),
        )
        dispatch(getUnitMetaData({ siteId, authToken }))
        dispatch(fetchAllLocations(authToken, siteId))
      })
  }

export const updateLocation =
  (
    authToken,
    siteId,
    locationId,
    data,
    facilityCoids,
    updateStatus,
    newStatus,
    user34,
  ) =>
  (dispatch) => {
    const urlForAuthUser = serverUrl + `Location/${locationId}`
    //TOOD if units - refresh Unit lis
    dispatch({ type: 'location/CLEAR_ALL_META_DATA' })
    axios({
      method: 'put',
      url: urlForAuthUser,
      data,
      headers: {
        authorization: authToken,
        siteId,
        Pragma: 'no-cache',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    }).then(function (response) {
      if (updateStatus) {
        dispatch(
          updateLocationStatus(
            authToken,
            siteId,
            true,
            locationId,
            newStatus,
            facilityCoids,
            user34,
          ),
        )
      } else {
        dispatch({ type: 'location/FETCHING_ALL_META_DATA', payload: true })
        dispatch(
          fetchSiteMetadata({
            facilityMetaDataString: new Set(facilityCoids),
            authToken,
          }),
        )
        dispatch(getUnitMetaData({ siteId, authToken }))
        dispatch(getUserSites({ user34, authToken }))
        dispatch(fetchAllLocations(authToken, siteId))
      }
    })
  }
export const createLocation =
  (authToken, siteId, data, facilityCoids, user34, unitId) => (dispatch) => {
    const urlForAuthUser = serverUrl + `Location`
    dispatch({ type: 'location/CLEAR_ALL_META_DATA' })
    axios({
      method: 'post',
      url: urlForAuthUser,
      data,
      headers: {
        authorization: authToken,
        Pragma: 'no-cache',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    }).then(function (response) {
      dispatch({ type: 'location/FETCHING_ALL_META_DATA', payload: true })
      if (unitId) {
        dispatch(fetchRoomMetadata(authToken, siteId, unitId))
      } else {
        dispatch(fetchRoomMetadata(authToken, siteId))
      }
      dispatch(getUnitMetaData({ siteId, authToken }))
      dispatch(getUserSites({ user34, authToken }))
      dispatch(fetchAllLocations(authToken, siteId))
    })
  }
