/**
 * Internal Imports
 * */
import UserSlidingPane from './userSlidingPane/UserSlidingPane'
import LocationsSlidingPane from './locationsSlidingPane/LocationsSlidingPane'
import ModifyReport from './modifyReportSlidingPane/ModifyReportSlidingPane'

const SlidingPane = ({
  path,
  sites,
  setShowDeleteUserModal,
  setDeleteUserData,
  hideSlidingPane,
  showHideClassName,
  showHideReportClassName,
  slidingPane,
  clinicalRoles,
  coid,
  authToken,
  authorizedGlobalAdmin,
  authorizedLocalAdmin,
  selectedSite,
  dispatch,
  ui,
  loggedInUser,
  selectedAdminSite,
  selectedDivision,
  selectedClinicalRole,
  userPermissions,
  allLocations,
  bedLoading,
  selectedUnit,
  selectedRoom,
  units,
  beds,
  singleLocationToEdit,
  positions,
  site,
  isVisible,
  user34,
  sortDirection,
  sortBy,
}) => {
  
  let typeOfSlidingPane
  if (
    path === 'snapshot' ||
    path === 'missingassignments' ||
    path === 'assignmentaudit'
  ) {
    typeOfSlidingPane = 'reports'
  } else {
    typeOfSlidingPane = path
  }

  switch (typeOfSlidingPane) {
    case 'users':
      const parentFacilities =
        sites?.reduce((acc, site) => {
          return acc.find((f) => f.id === site.parent.id)
            ? acc
            : [
                ...acc,
                {
                  ...site.parent,
                  key: site.parent.id,
                  text: site.parent.displayName,
                  value: site.parent.id,
                },
              ]
        }, []) || []

      return (
        <UserSlidingPane
          setShowDeleteUserModal={setShowDeleteUserModal}
          setDeleteUserData={setDeleteUserData}
          hideSlidingPane={hideSlidingPane}
          showHideClassName={showHideClassName}
          slidingPaneOpen={ui.slidingPane.open}
          data={slidingPane.data}
          open={slidingPane.open}
          facilities={parentFacilities}
          source={slidingPane.source}
          clinicalRoles={clinicalRoles}
          coid={coid}
          authToken={authToken}
          authorizedGlobalAdmin={authorizedGlobalAdmin}
          authorizedLocalAdmin={authorizedLocalAdmin}
          selectedSite={selectedSite}
          dispatch={dispatch}
          ui={ui}
          loggedInUser={loggedInUser}
          selectedAdminSite={selectedAdminSite}
          selectedDivision={selectedDivision}
          selectedClinicalRole={selectedClinicalRole}
          userPermissions={userPermissions}
          sortDirection={sortDirection}
          sortBy={sortBy}
        />
      )
    case 'locations':
      return (
        <LocationsSlidingPane
          hideSlidingPane={hideSlidingPane}
          showHideClassName={showHideClassName}
          slidingPaneOpen={ui.slidingPane.open}
          data={slidingPane.data}
          open={slidingPane.open}
          authToken={authToken}
          coid={coid}
          selectedSite={selectedSite}
          allLocations={allLocations}
          path={path}
          source={slidingPane.source}
          bedLoading={bedLoading}
          authorizedGlobalAdmin={authorizedGlobalAdmin}
          authorizedLocalAdmin={authorizedLocalAdmin}
          sites={sites}
          loggedInUser={loggedInUser}
          dispatch={dispatch}
          selectedDivision={selectedDivision}
          selectedUnit={selectedUnit}
          selectedRoom={selectedRoom}
          units={units}
          beds={beds}
          singleLocationToEdit={singleLocationToEdit}
        />
      )
    case 'reports':
      return (
        <ModifyReport
          hideSlidingPane={hideSlidingPane}
          showHideReportClassName={showHideReportClassName}
          slidingPaneOpen={ui.reportSlidingPane.open}
          showHideClassName={showHideClassName}
          isVisible={isVisible}
          open={slidingPane.open}
          units={units}
          path={path}
          positions={positions}
          site={site}
          authToken={authToken}
          user34={user34}
        />
      )
    default:
      return <></>
  }
}

export default SlidingPane
