/**
 * External Imports
 * */
import { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
/**
 * Interanl Imports
 * */
import ReportsList from './reportsList'
import Flex from '../reusableComponents/styleLibrary'

import { naturalSort } from '../../utils/naturalSort'

const getHeaders = (str) => {
  const columnHeaders = {
    missingassignments: [
      { label: 'UNIT', key: 'unit', multiplier: 1, sortable: false },
      { label: 'BED', key: 'bed', multiplier: 1, sortable: false },
      { label: 'PATIENT', key: 'patientName', multiplier: 1, sortable: false },
      { label: 'MISSING', key: 'missing', multiplier: 1, sortable: false },
      {
        label: 'MISSING SINCE',
        key: 'missingSince',
        multiplier: 1,
        sortable: true,
      },
      {
        label: 'ASSIGNMENT FILLED',
        key: 'assignmentFilled',
        multiplier: 1,
        sortable: false,
      },
    ],
    snapshot: [
      { label: 'UNIT', key: 'unit', multiplier: 1, sortable: false },
      { label: 'BED', key: 'bed', multiplier: 1, sortable: false },
      { label: 'PATIENT', key: 'patientName', multiplier: 1, sortable: false },
      { label: 'CARE TEAM', key: 'careTeam', multiplier: 0, sortable: false },
      {
        label: 'TEAM LAST UPDATED',
        key: 'teamLastUpdated',
        multiplier: 1,
        sortable: false,
      },
    ],
    assignmentaudit: [
      { label: 'TIME', key: 'time', multiplier: 1, sortable: true },
      { label: 'UNIT', key: 'unit', multiplier: 1, sortable: false },
      { label: 'BED', key: 'bed', multiplier: 1, sortable: false },
      { label: 'PATIENT', key: 'patientName', multiplier: 1, sortable: false },
      { label: 'ACTION', key: 'action', multiplier: 1, sortable: false },
      { label: 'POSITION', key: 'position', multiplier: 1, sortable: false },
      {
        label: 'STAFF MEMBER',
        key: 'staffMember',
        multiplier: 1,
        sortable: false,
      },
      { label: 'MADE BY', key: 'madeBy', multiplier: 1, sortable: false },
    ],
  }
  return columnHeaders[str]
}

const ReportsTable = ({ path, reportingData, snapshotPosition }) => {
  const [height, setHeight] = useState(
    path === 'missingassignments'
      ? window.innerHeight - 332
      : window.innerHeight - 302,
  )
  const [sortDirection, setSortDirection] = useState(true)
  const [selected, setSelected] = useState(
    path === 'missingassignments' ? 'missingSince' : '',
  )

  const columnHeaders = getHeaders(path)

  // useCallback: Memoizes the tableHeight function to avoid recreating it on every render.
  const tableHeight = useCallback(
    (e) => {
      setHeight(path === 'missingassignments' ? e - 332 : e - 302)
    },
    [path],
  )
  // useEffect: Adds the resize event listener and ensures it is removed when the component unmounts.
  useEffect(() => {
    const handleResize = _.debounce((e) => {
      const target = e.currentTarget
        ? e.currentTarget.innerHeight
        : e.srcElement.innerHeight
      tableHeight(target)
    }, 750)

    window.addEventListener('resize', handleResize)
    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [tableHeight])

  const headerColumnClickHandler = (index, header) => {
    setSortDirection(!sortDirection)
    setSelected(header.key)
  }
  const handleSort = ({ key, direction }) => {
    if (!key) return reportingData
    const hash = []
    const list = reportingData
    list.forEach((item) => {
      if (key === 'clinicalRole') {
        hash.push(item[key].displayName.toUpperCase())
      } else {
        hash.push(item[key].toUpperCase())
      }
    })

    const orderedHash = hash.sort(naturalSort)
    const orderedList = []
    for (let i = 0; i < orderedHash.length; i++) {
      for (let j = 0; j < list.length; j++) {
        if (key === 'clinicalRole') {
          if (orderedHash[i] === list[j][key].displayName.toUpperCase()) {
            orderedList.push(list[j])
          }
        } else if (orderedHash[i] === list[j][key].toUpperCase()) {
          orderedList.push(list[j])
        }
      }
    }
    const result = _.uniqBy(orderedList, 'id')
    return direction ? result : result.reverse()
  }

  const sortedList = handleSort({
    key: selected,
    direction: sortDirection,
  })

  return (
    <div style={{ width: '100%' }}>
      {/* <ToolTip showToolTip={showToolTip} hideToolTip={hideToolTip} /> */}
      <Flex
        style={{
          display: 'flex',
          height: '60px',
          justifyContent: 'space-between',
        }}
      >
        {columnHeaders.map((header, index) => (
          <Flex
            key={header.key}
            style={{
              padding: `15px 0 0 ${header.key === 'careTeam' ? '6px' : '0'}`,
              cursor: header.sortable ? 'pointer' : '',
              flex: `${
                header.key === 'careTeam'
                  ? '0 0 720px'
                  : '1 1 ' +
                    (100 / columnHeaders.length) * header.multiplier +
                    '%'
              }`,
            }}
            onClick={() => {
              if (header.sortable) {
                headerColumnClickHandler(index, header)
              }
            }}
          >
            <div
              style={{
                color: selected === header.key ? '#03173e' : '#58595B',
                fontSize: '14px',
                fontWeight: '500',
                paddingTop: '5px',
                paddingLeft: index === 0 ? '25px' : '0px',
                marginRight: '10px',
              }}
            >
              {header.label}
            </div>
            {header.label ? (
              <div style={{ paddingTop: '2px' }}>
                <i
                  className="material-icons"
                  style={{
                    color: selected === header.key ? '#0085ca' : '#898B8E',
                  }}
                >
                  {header.label === 'MISSING SINCE' || header.label === 'TIME'
                    ? selected === header.key && !sortDirection
                      ? 'arrow_downward'
                      : 'arrow_upward'
                    : index === 0}
                </i>
              </div>
            ) : (
              <></>
            )}
          </Flex>
        ))}
      </Flex>
      <Flex
        direction="column"
        style={{
          height: height + 'px',
          overflowY: 'scroll',
          paddingBottom: 60,
          borderTop: 'solid back 1px',
        }}
      >
        {reportingData.length > 0 ? (
          <ReportsList
            reportingData={sortedList}
            path={path}
            columns={columnHeaders}
            snapshotPosition={snapshotPosition}
          />
        ) : (
          <div style={{ marginLeft: '40px', fontSize: '1.5em' }}>
            There are no entries matching your search criteria.
          </div>
        )}
      </Flex>
    </div>
  )
}

export default ReportsTable
