/**
 * External Imports
 * */
import { useEffect, useState } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
/**
 * Internal Imports
 * */
import Authenticate from './Authenticate'
import Documentation from './Documentation'
import Health from './Health'
import RedirectPage, { validRoutes } from './Redirect'
import { createBrowserHistory } from 'history'
import Header from '../headers/header/Header'
import navLinks from '../routes/NavLinks'
import ManageLinks from '../routes/ManageLinks'
import ReportsLinks from '../routes/ReportsLinks'
import Loading from '../reusableComponents/neutronComponents/Spinner'
import TimeOutError from '../reusableComponents/errorPages/TimeOutError'
import Generic from '../reusableComponents/errorPages/Generic'
import Downtime from '../reusableComponents/errorPages/Downtime'
import BadRequest from '../reusableComponents/errorPages/400'
import Unauthorized from '../reusableComponents/errorPages/401'
import Forbidden from '../reusableComponents/errorPages/403'
import NotFound from '../reusableComponents/errorPages/404'
import RequestTimeout from '../reusableComponents/errorPages/408'
import ServerError from '../reusableComponents/errorPages/500'
import ServiceUnavailable from '../reusableComponents/errorPages/503'

import {
  applicationStatus,
  authenticateUser,
  managePageAuthenticateUser,
} from '../../redux/actions/user'
/**
 * Style Imports
 * */
import '../../App.scss'
import styled from 'styled-components'

const browserHistory = createBrowserHistory();

const GateKeeper = ({
  sites,
  frontendLock,
  globalUserSettings,
  locations,
  selectedStaffSlots,
  stagedStaffSlots,
  ui,
  units,
  user,
  user34,
  userGroups,
  dispatch,
}) => {
  const [userIsAuthenticated, setUserIsAuthenticated] = useState(false)
  const [, setShowDisabledNavInfoModal] = useState(false)
  const [disabledNav, setDisabledNav] = useState(false)
  const [didMount, setDidMount] = useState(false)
  const [route, setRoute] = useState('')

  const showHideDisabledNavInfoModal = (boolean) => {
    boolean
      ? setShowDisabledNavInfoModal(true)
      : setShowDisabledNavInfoModal(false)
  }

  const returnRoute = (route, isAllowed) => {
    const component = isAllowed ? route.component : () => <Forbidden />

    const componentWithProps = component({
      showHideDisabledModal: showHideDisabledNavInfoModal,
      disabledNav: disabledNav,
      selectedStaffSlots: selectedStaffSlots,
      stagedStaffSlots: stagedStaffSlots,
      userSettings: user.userSettings,
    })
    return (
      <Route
        exact
        key={route.link}
        path={route.link}
        render={componentWithProps}
      />
    )
  }
  const dynamicRoutes = (navLinks) => {
    let isAllowed = false
    if (user.user) {
      const allowedRoutes = []

      navLinks.forEach((navLink) => {
        if (
          navLink.allowedRoles.some((link) =>
            user.userPermissions.includes(link),
          )
        ) {
          isAllowed = true
          allowedRoutes.push(returnRoute(navLink, isAllowed))
        }
      })
      return allowedRoutes
    }
  }

  useEffect(() => {
    setDidMount(true)
  }, [])

  useEffect(() => {
    if (didMount) {
      const selectedSiteId = sites.selectedSiteId
      const userSiteAccess = sites.sites
      if (userSiteAccess && !userIsAuthenticated) {
        dispatch(applicationStatus(user.token))
        setUserIsAuthenticated(true)
        dispatch(
          authenticateUser({
            user34: user34,
            authToken: user.token,
            siteId: selectedSiteId,
          }),
        )
        dispatch(
          managePageAuthenticateUser({
            user34: user34,
            authToken: user.token,
            siteId: selectedSiteId,
          }),
        )
      }

      // check if any selections are pending
      const isEmpty = !Object.keys(stagedStaffSlots).length

      if (!isEmpty && !disabledNav) {
        setDisabledNav(true)
        setShowDisabledNavInfoModal(true)
      } else if (isEmpty && disabledNav) {
        setDisabledNav(false)
        setShowDisabledNavInfoModal(false)
      }
    }
  }, [
    didMount,
    disabledNav,
    sites,
    user34,
    stagedStaffSlots,
    user.token,
    userIsAuthenticated,
    dispatch,
  ])

  useEffect(() => {
    if (
      didMount &&
      window.location.pathname !== '/redirect' &&
      window.location.pathname !== '/' &&
      validRoutes.includes(window.location.pathname)
    ) {
      localStorage.setItem('ctaLastPath', window.location.pathname)
    }
  }, [didMount, window.location.pathname])

  useEffect(() => {
    const savedPath =
      localStorage.getItem('ctaLastPath') || '/locationassignments'
    if (!validRoutes.includes(savedPath)) {
      setRoute('/locationassignments')
    } else {
      setRoute(savedPath)
    }
  }, [])

  if (!user.token) {
    return (
      <Router history={browserHistory}>
        <Switch>
          <Route path="/docs" component={Documentation} />
          <Route path="/health" component={Health} />
          <Route path="/redirect" component={RedirectPage} />
          <Route path="/" component={Authenticate} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    )
  }
  if (ui.timeOutError) {
    return <TimeOutError />
  }
  const userError = user.error
  const siteError = sites.error
  const unitsError = units.error
  const userGroupError = userGroups.error
  const locationError = locations.error

  const errorArray = [
    userError,
    siteError,
    unitsError,
    userGroupError,
    locationError,
  ]

  if (
    (sites.fetching && window.location.pathname !== '/manage/locations') ||
    (units.fetching && !user && !window.location.pathname.includes('manage')) ||
    user.fetching ||
    (user.fetchingGlobalUserSettings &&
      !window.location.pathname.includes('manage'))
  ) {
    return (
      <div
        style={{
          height: '300px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loading square="100px" />
      </div>
    )
  }
  if (
    frontendLock &&
    !user.user &&
    user.user.authorizationRoles[0].systemName === 'CTA.AuthRoles.GlobalAdmin'
  )
    return <Downtime />
  for (let error of errorArray) {
    if (error !== null && error) {
      if (error.status === 401) {
        return <Unauthorized />
      } else if (error.status === 400) {
        return <BadRequest />
      } else if (error.status === 403) {
        return <Forbidden />
      } else if (error.status === 404) {
        return <NotFound />
      } else if (error.status === 408) {
        return <RequestTimeout />
      } else if (error.status === 500) {
        return <ServerError />
      } else if (error.status === 503) {
        return <ServiceUnavailable />
      } else {
        return <Generic />
      }
    }
  }

  const sitesLoadedAndExist = sites.sites || []

  const selectedUnitIds = units.selectedUnitIds || []

  if (sitesLoadedAndExist?.length > 0) {
    return (
      <Router style={{ height: '100%' }} history={browserHistory}>
        <Background style={{ height: '100%' }} className="flex flex-column">
          <Header
            selectedUnitIds={selectedUnitIds}
            user={user.user}
            navLinks={navLinks}
            showHideDisabledModal={showHideDisabledNavInfoModal.bind(this)}
            disabledNav={disabledNav}
            stagedStaffSlots={stagedStaffSlots}
            units={units}
            uiState={ui}
            globalUserSettings={globalUserSettings}
          />
          <Inner>
            <Switch>
              <Route exact path="/" render={() => <Redirect to={route} />} />
              <Route path="/redirect" render={() => <Redirect to="/" />} />
              {/*creates dynamic roles based on user permissions*/}
              {dynamicRoutes(navLinks)}
              {dynamicRoutes(ManageLinks)}
              {dynamicRoutes(ReportsLinks)}
            </Switch>
          </Inner>
        </Background>
      </Router>
    )
  } else {
    return <Unauthorized />
  }
}

const Inner = styled.div`
  letter-spacing: 0.2px;
  font-size: 14px;
  width: 100%;
  background-color: rgb(245, 247, 249);
  min-height: calc(100vh - 64px);
`
const Background = styled.div`
  background-color: #03173e;
  height: 100%;
`

const mapReduxStateToProps = function (state) {
  return {
    user34: state.user.user34,
    frontendLock: state.user.frontendLock,
    user: state.user,
    userGroups: state.userGroups,
    locations: state.locations,
    sites: state.sites,
    units: state.units,
    staffPool: state.staffPool,
    stagedStaffSlots: state.stagedStaffSlots.stagedStaffSlots,
    selectedStaffSlots: state.selectedStaffSlots.selectedStaffSlots,
    ui: state.ui,
    globalUserSettings: state.user.globalUserSettings,
  }
}

export default connect(mapReduxStateToProps)(GateKeeper)
